<template>
	<div v-if="drawer">
		<v-navigation-drawer v-model="drawerVal" fixed width="700" right temporary stateless>
			<div class="drawer-wrapper">
				<v-flex class="py-3 px-5 d-flex border-bottom drawer-header">
					<div class="font-level-3-bold">
						{{ titleStatus }} Reminder
						<template v-if="reminderSingleData && reminderSingleData.barcode">
							({{ reminderSingleData.barcode }})
						</template>
						<template v-if="companyName"> :-{{ companyName }}</template>
					</div>
					<v-spacer></v-spacer>
					<v-btn depressed tile class="mr-2" v-on:click="closeDrawer()">Cancel</v-btn>
					<v-btn
						tile
						depressed
						color="white--text"
						class="blue darken-4"
						v-on:click="update_or_create"
						:loading="pageLoading"
						:disabled="pageLoading || !formValid"
						>Save</v-btn
					>
				</v-flex>
				<v-form
					ref="form"
					v-model.trim="formValid"
					lazy-validation
					v-on:submit.stop.prevent="update_or_create"
				>
					<div class="drawer-content pt-5 px-5">
						<div class="mb-3" v-if="related == null">
							<div class="d-flex pt-1">
								<label class="fw-500 required"> Related To</label>
							</div>
							<v-radio-group
								row
								hide-details
								class="mt-0"
								v-model="reminder.related_to"
								v-on:change="getRelatedField(false)"
							>
								<template>
									<v-radio label="Lead" value="lead"></v-radio>
									<v-radio label="Customer" value="customer"></v-radio>
									<v-radio label="Task" value="task"></v-radio>
									<v-radio label="Meeting" value="meeting"></v-radio>
								</template>
							</v-radio-group>
						</div>
						<div class="mb-3" v-if="related == null">
							<div class="d-flex pt-1">
								<label class="fw-500 required text-capitalize">
									<template v-if="reminder.related_to == 'lead'">Company Name</template>
									<template v-else-if="reminder.related_to == 'task'">Task </template>
									<template v-else-if="reminder.related_to == 'customer'">Customer </template>
									<template v-else-if="reminder.related_to == 'meeting'">Meeting </template>
								</label>
							</div>
							<div>
								<div v-if="reminder.related == null" class="d-flex">
									<v-autocomplete
										:items="dataLists"
										item-text="text"
										item-value="value"
										:placeholder="getPlaceholder(reminder.related_to)"
										depressed
										hide-details
										outlined
										v-model="reminder.related_id"
										:rules="[vrules.required(reminder.related_id, 'Related Type')]"
										:class="{
											required: !reminder.related_id,
										}"
									>
										<template v-slot:selection="data">
											<span class="text-uppercase">
												{{ data.item.text }}
												{{ data.item.lead_barcode ? `(${data.item.lead_barcode})` : null }}
											</span>
										</template>
										<template v-slot:item="data">
											<template>
												<v-list-item-content>
													<v-list-item-title
														>{{ data.item.text
														}}{{
															data.item.lead_barcode ? `(${data.item.lead_barcode})` : null
														}}</v-list-item-title
													>
												</v-list-item-content>
											</template>
										</template>
									</v-autocomplete>
									<v-icon
										v-if="reminder.related_to == 'lead' || related == 'lead'"
										v-on:click="CreateLeadDialog = true"
										class="primary--text"
									>
										mdi-cog
									</v-icon>
								</div>
							</div>
						</div>
						<div class="mb-3">
							<div class="d-flex pt-1">
								<label class="fw-500">Title</label>
							</div>
							<v-text-field
								depressed
								hide-details
								outlined
								v-model="reminder.title"
								:disabled="pageLoading"
								:loading="pageLoading"
								placeholder="Title"
							></v-text-field>
						</div>
						<div class="mb-3">
							<div class="d-flex pt-1">
								<label class="fw-500">Reminder Type </label>
							</div>
							<AutoCompleteInput
								dense
								id="reminder-type"
								hide-details
								placeholder="Select Reminder Type"
								:items="reminder_type_items"
								:disabled="pageLoading || reminder?.id > 0"
								:loading="pageLoading"
								item-text="text"
								v-model="reminder.type"
								item-value="value"
								v-on:change="get_reminder_values()"
							>
							</AutoCompleteInput>
							<!-- reminder.related_to=='meeting'?reminder_type_items2: -->
						</div>
						<div class="mb-3">
							<div class="d-flex pt-1">
								<label class="fw-500 required">Start Date</label>
							</div>
							<DateTimePicker
								dense
								id="reminder-start-date"
								hide-details
								:disabled="pageLoading"
								:loading="pageLoading"
								v-model="reminder.start_date"
								:placeholder="reminder.type != 1 ? 'Select Start Date...' : 'Select Start Date...'"
								v-on:change="get_reminder_values()"
								:rules="[
									vrules.required(reminder.start_date, reminder.type != 1 ? 'Start Date' : 'Start Date'),
								]"
								:class="{ required: !reminder.start_date }"
							></DateTimePicker>
						</div>
						<div class="mb-3" v-if="reminder.type != 1">
							<div class="d-flex pt-1">
								<label class="fw-500">Frequency </label>
							</div>
							<TextInput
								v-if="false"
								dense
								id="reminder-frequency"
								hide-details
								type="number"
								placeholder="Enter Frequency..."
								:disabled="pageLoading"
								:loading="pageLoading"
								class="mt-0"
								v-model="reminder.frequency"
								v-on:keyup="get_reminder_values()"
							></TextInput>
							<v-text-field
								type="Number"
								v-mask="'####'"
								depressed
								hide-details
								outlined
								placeholder="Enter Frequency..."
								v-model="reminder.frequency"
								:disabled="pageLoading || reminder?.id > 0"
								:loading="pageLoading"
								v-on:keyup="get_reminder_values()"
							></v-text-field>
						</div>
						<div class="mb-3" v-if="reminder.type != 1">
							<div class="d-flex pt-1">
								<label class="fw-500">End Date</label>
							</div>
							<DateTimePicker
								dense
								readonly
								id="reminder-start-date"
								hide-details
								:disabled="true"
								:loading="pageLoading"
								v-model="reminder.end_date"
								:placeholder="'Select End Date...'"
								v-on:change="get_reminder_values()"
								:rules="[vrules.required(reminder.end_date, reminder.type != 1 ? 'End Date' : 'End Date')]"
								:class="{ required: !reminder.end_date }"
							></DateTimePicker>
						</div>
						<div class="py-3" v-if="reminderSingleData?.id > 0">
							<!-- <RadioInputCustom
								v-model="reminder.extention"
								row
								color="blue darken-4"
								:items="[
									{
										label: 'Extend this reminder',
										value: 'single',
									},
									{
										label: 'Extend all upcoming reminder',
										value: 'multiple',
									},
								]"
							/> -->
							<v-radio-group v-model="reminder.extention" row hide-details class="mt-0">
								<v-radio class="custome-radio" value="single">
									<template #label>
										<v-chip color="primary" label outlined small class="ml-2" style="margin-right: 90px">
											<span style="font-size: 13px"> Extend this reminder </span>
										</v-chip>
									</template>
								</v-radio>
								<v-radio class="custome-radio" value="multiple" v-if="reminder.type != 1">
									<template #label>
										<v-chip color="red" label outlined small class="ml-2">
											<span style="font-size: 13px"> Extend all upcoming reminder </span>
										</v-chip>
									</template>
								</v-radio>
							</v-radio-group>
						</div>

						<div class="mb-3">
							<!-- 	<div class="d-flex pt-1">
								<label class="fw-500 required">Priority</label>
							</div> -->
							<RadioInputCustom
								v-model="reminder.callType"
								row
								color="blue darken-4"
								:items="[
									{
										label: 'Call',
										value: 'call',
									},
									{
										label: 'Email',
										value: 'email',
									},
									{
										label: 'Other',
										value: 'other',
									},
								]"
							/>
							<!-- <v-radio-group row hide-details class="mt-0" v-model="reminder.callType">
								<v-radio color="green" label="Call" value="call"></v-radio>
								<v-radio color="red" label="Email" value="email"></v-radio>
								<v-radio color="cyan" label="Other" value="other"></v-radio>
							</v-radio-group> -->
						</div>

						<template v-if="reminder.callType == 'other'">
							<div class="mb-3">
								<div class="d-flex pt-1">
									<label class="fw-500">Customer</label>
								</div>
								<AutoCompleteInput
									dense
									id="customer"
									hide-details
									placeholder="Customer"
									:items="customerList"
									:disabled="pageLoading"
									:loading="pageLoading"
									item-text="text"
									v-model="reminder.customer"
									item-value="value"
									@change="filterProject($event)"
								>
								</AutoCompleteInput>
								<!-- v-on:change="get_reminder_values()"-->
							</div>
							<div class="mb-3">
								<div class="d-flex pt-1">
									<label class="fw-500">Project</label>
								</div>
								<AutoCompleteInput
									dense
									id="project"
									hide-details
									placeholder="Project"
									:items="projectList"
									:disabled="pageLoading"
									:loading="pageLoading"
									item-text="text"
									v-model="reminder.project"
									item-value="value"
								>
								</AutoCompleteInput>
								<!-- v-on:change="get_reminder_values()"-->
							</div>
						</template>

						<div class="mb-3">
							<div class="d-flex pt-1">
								<label class="fw-500">Attendees </label>
							</div>
							<v-autocomplete
								:items="userList"
								item-text="display_name"
								item-value="id"
								depressed
								:readonly="
									currentUser.role != 1 && currentUser.role != 4 && userType != 'accounts' && isMaster == 0
								"
								hide-details
								outlined
								hide-selected
								multiple
								color="blue darken-4"
								small-chips
								placeholder="Attendees"
								deletable-chips
								v-model="reminder.attendees"
								:disabled="pageLoading"
								:loading="pageLoading"
							>
								<template v-slot:selection="data">
									<v-chip
										small
										v-bind="data.attrs"
										:input-value="data.selected"
										close
										@click="data.select"
										@click:close="remove(data.item)"
									>
										<v-avatar left>
											<img v-if="data.item.profile_img" :src="data.item.profile_img" />
											<img v-else src="https://ams-dev.businessthrust.com//media/misc/no_photo_found.png" />
										</v-avatar>
										{{ data.item.display_name }}
									</v-chip>
								</template>
								<template v-slot:item="data">
									<template>
										<v-list-item-avatar>
											<img v-if="data.item.profile_img" :src="data.item.profile_img" />
											<img v-else src="https://ams-dev.businessthrust.com//media/misc/no_photo_found.png" />
										</v-list-item-avatar>
										<v-list-item-content>
											<v-list-item-title v-html="data.item.display_name"></v-list-item-title>
										</v-list-item-content>
									</template>
								</template>
							</v-autocomplete>
						</div>
						<div class="mb-3">
							<div class="d-flex pt-1">
								<label class="fw-500">Description</label>
							</div>
							<ckeditor :editor="editor" v-model="reminder.description" :config="editorConfig"></ckeditor>
						</div>
					</div>
				</v-form>
				<div style="min-height: 55px">
					<v-flex class="py-3 px-5 d-flex border-bottom drawer-footer">
						<v-spacer></v-spacer>
						<v-btn depressed tile class="mr-2" v-on:click="closeDrawer()">Cancel</v-btn>
						<v-btn
							tile
							depressed
							color="white--text"
							class="blue darken-4"
							v-on:click="update_or_create"
							:loading="pageLoading"
							:disabled="pageLoading || !formValid"
							>Save</v-btn
						>
					</v-flex>
				</div>
				<Dialog :dialog="extentionDialog" :dialog-width="710" content-class="testdata">
					<template v-slot:title>Extended Reminder <v-spacer></v-spacer> </template>
					<template v-slot:body>
						<v-row class="delete-dialog">
							<v-col md="2" class="py-0 text-right my-auto">
								<span class="svg-icon svg-icon-lg delete-confirm-icon">
									<v-icon color="green" size="55">mdi-check</v-icon>
								</span>
							</v-col>
							<v-col md="10" class="py-0 my-auto">
								<p class="btx-p m-0" style="font-size: 18px">
									<template v-if="reminder && reminder.extention && reminder.extention == 'single'">
										You have successfully updated only this reminder
									</template>
									<template v-if="reminder && reminder.extention && reminder.extention == 'multiple'">
										You have successfully updated all reminders
									</template>
								</p>
							</v-col>
						</v-row>
					</template>
					<template v-slot:action>
						<v-btn tile depressed color="white--text" class="blue darken-4" v-on:click="confirmDialog">
							Okay
						</v-btn>
					</template>
				</Dialog>
			</div>
		</v-navigation-drawer>
	</div>
</template>
<script>
import Dialog from "@/view/components/Dialog";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { GET, POST, PUT } from "@/core/services/store/request.module";
import { map, cloneDeep } from "lodash";
import { SET_MESSAGE, SET_ERROR } from "@/core/services/store/common.module";
import { mapGetters } from "vuex";
import MainMixin from "@/core/mixins/main.mixin.js";
/* import DatePicker from "@/view/components/DatePicker"; */
import AutoCompleteInput from "@/view/components/AutoCompleteInput";
import TextInput from "@/view/components/TextInput";
import RadioInputCustom from "@/view/components/RadioInputCustom";
import MomentJS from "moment-timezone";
import DateTimePicker from "@/view/components/DateTimePicker";

export default {
	mixins: [MainMixin],
	name: "CreateReminder",
	props: {
		drawer: {
			type: Boolean,
			default: false,
		},
		reminderSingleData: {
			type: Object,
		},
		attendies: {
			type: Object,
		},
		related: {
			type: String,
			default: null,
		},
		name: {
			type: String,
			default: null,
		},
		relatedId: {
			type: Number,
			default: 0,
		},
		titleStatus: {
			type: String,
		},
	},
	watch: {
		name: {
			deep: true,
			immediate: true,
			handler(param) {
				this.companyName = cloneDeep(param);
			},
		},
		drawer: {
			deep: true,
			immediate: true,
			handler(param) {
				this.drawerVal = param;
			},
		},
		reminderSingleData: {
			deep: true,
			immediate: true,
			handler(param) {
				console.log({ param: param });
				if (param && param?.id) {
					this.reminder.title = param.title ? param.title : null;
					this.reminder.id = param.id ? param.id : 0;
					this.reminder.start_date = param.start_date
						? MomentJS(param.start_date, "YYYY-MM-DD HH:mm:ss").format("YYYY-MM-DD HH:mm")
						: null;
					this.reminder.callType = param.callType;
					/* 	? MomentJS(param.start_date, "YYYY-MM-DD HH:II:SS").format("YYYY-MM-DD HH:II:SS")
						: null; */
					this.reminder.end_date = param.end_date
						? MomentJS(param.end_date, "YYYY-MM-DD HH:mm:ss").format("YYYY-MM-DD HH:mm")
						: null;
					this.reminder.type = param.type ? param.type : 1;
					this.reminder.frequency = param.frequency ? param.frequency : 1;
					this.reminder.description = param.description ? param.description : null;
					this.reminder.customer = param.customer ? param.customer : 0;
					this.reminder.project = param.project ? param.project : 0;
				}
			},
		},
		attendies: {
			deep: true,
			immediate: true,
			handler(param) {
				this.reminder.attendees = map(param.attendies, "id");
			},
		},
	},
	components: {
		/* DatePicker, */
		Dialog,
		AutoCompleteInput,
		DateTimePicker,
		TextInput,
		RadioInputCustom,
	},
	data() {
		return {
			customerList: [],
			projectList: [],
			allProjects: [],
			formValid: true,
			extentionDialog: false,
			drawerVal: false,
			companyName: null,
			today: new Date(),
			timeList: [],
			userList: [],
			reminder_type_items: [
				{ value: 1, text: "One Time Reminder" },
				{ value: 2, text: "Daily Reminder" },
				{ value: 3, text: "Weekly Reminder" },
				{ value: 4, text: "Monthly Reminder" },
				{ value: 5, text: "Quarterly Reminder" },
				{ value: 6, text: "Half Yearly Reminder" },
			],
			reminder_type_items2: [
				{ value: 1, text: "One Time Reminder" },
				{ value: 3, text: "Weekly Reminder" },
			],

			pageLoading: false,
			dataLists: [],
			editor: ClassicEditor,
			editorData: "<p>Description</p>",
			editorConfig: {
				// The configuration of the editor.
			},

			date_dialog: false,
			from_date_dialog: false,
			reminder: {
				id: 0,
				extention: "single",
				title: null,
				start_date: this.formatDateTimeRaw(new Date()),
				end_date: null,
				callType: "other",
				type: 1,
				frequency: 1,
				related_to: "customer",
				related_id: 0,
				description: null,
				customer: 0,
				project: 0,
			},
		};
	},
	methods: {
		filterProject(event) {
			const projects = this.allProjects;
			const customerId = event;

			this.projectList = map(projects, (row) => {
				if (row.customer == customerId) {
					return { ...row };
				}
			}).filter((notUndefined) => notUndefined !== undefined);
		},
		confirmDialog() {
			this.$emit("close", true);
			this.extentionDialog = false;
		},
		getPlaceholder(type) {
			if (type == "lead") {
				return "Select Lead";
			} else if (type == "customer") {
				return "Select Customer";
			} else if (type == "task") {
				return "Select Task";
			} else if (type == "meeting") {
				return "Select Meeting";
			}
		},
		getRelatedField() {
			this.dataLists = [];
			this.$store
				.dispatch(GET, { url: `related-field` })
				.then((data) => {
					this.dataLists = data[this.reminder.related_to];
					this.projectList = data && data.projects;
					this.allProjects = this.projectList;
					this.customerList = data && data.all_customers;
				})
				.catch((error) => {
					console.log({ error });
				});
		},
		get_reminder_values() {
			let reminderParseDate = this.reminder.start_date;
			if (this.reminder.start_date) {
				for (var i = 0; i < this.reminder.frequency; i++) {
					if (this.reminder.type == 6) {
						/* Half Yearly */
						reminderParseDate = MomentJS(reminderParseDate, "YYYY-MM-DD HH:mm").add(6, "months");
					}

					if (this.reminder.type == 5) {
						/* Quarterly */
						reminderParseDate = MomentJS(reminderParseDate, "YYYY-MM-DD HH:mm").add(3, "months");
					}

					if (this.reminder.type == 4) {
						/* Monthly */
						reminderParseDate = MomentJS(reminderParseDate, "YYYY-MM-DD HH:mm").add(1, "months");
					}

					if (this.reminder.type == 3) {
						/* Weekly */
						reminderParseDate = MomentJS(reminderParseDate, "YYYY-MM-DD HH:mm").add(7, "days");
					}

					if (this.reminder.type == 2) {
						/* Daily */
						reminderParseDate = MomentJS(reminderParseDate, "YYYY-MM-DD HH:mm").add(1, "days");
					}

					if (this.reminder.type == 1) {
						/* One Time */
						reminderParseDate = MomentJS(reminderParseDate, "YYYY-MM-DD HH:mm").add(7, "days");
					}
				}
				console.log({ format_end_date: reminderParseDate.format("YYYY-MM-DD HH:mm") });
				return (this.reminder.end_date = reminderParseDate.format("YYYY-MM-DD HH:mm"));
			}
		},
		remove(item) {
			const index = this.reminder.attendees.indexOf(item.id);
			this.reminder.attendees.splice(index, 1);
		},
		getReminderSetting() {
			this.$store
				.dispatch(GET, { url: `meeting-setting` })
				.then((data) => {
					this.timeList = data.time;
					this.userList = data.users;
				})
				.catch((error) => {
					console.log({ error });
				});
		},
		/* openData(){
			if (this.reminderSingleData && this.reminderSingleData.id) {
				this.extentionDialog = true
			} else {
				this.saveReminderData();
			}
			
		}, */
		update_or_create() {
			const formErrors = this.validateForm(this.$refs["form"]);

			this.$refs["form"].validate();

			if (formErrors.length) {
				this.$store.commit(SET_ERROR, this.errors.concat(formErrors));
				return false;
			}

			if (!this.$refs["form"].validate()) {
				return false;
			}
			if (this.reminderSingleData && this.reminderSingleData.id) {
				this.updateReminderData();
			} else {
				this.saveReminderData();
			}
		},
		updateReminderData() {
			this.reminder.related_id = this.reminder.related_id ? this.reminder.related_id : this.relatedId;
			this.reminder.related_to = this.reminder.related_to ? this.reminder.related_to : this.related;
			if (this.reminder.type == 1) {
				this.reminder.end_date = null;
			}
			this.pageLoading = true;
			this.$store
				.dispatch(PUT, {
					url: `reminder/${this.reminderSingleData.id}`,
					data: this.reminder,
				})
				.then((data) => {
					this.$store.commit(SET_MESSAGE, [
						{ model: true, message: "Success ! Reminder updated successfully." },
					]);
					this.$emit("updateRemindSuccess", data);
					if (data) {
						this.$emit("deleteSuccess", data);
					}

					this.reminder.description = "";
					//this.$emit("close", true);

					this.drawerVal = false;
					this.extentionDialog = true;
				})
				.catch((error) => {
					console.log({ error });
				})
				.finally(() => {
					this.pageLoading = false;
				});
		},
		saveReminderData() {
			this.pageLoading = true;
			this.reminder.related_id = this.reminder.related_id ? this.reminder.related_id : this.relatedId;
			this.reminder.related_to = this.reminder.related_to ? this.reminder.related_to : this.related;
			if (this.reminder.type == 1) {
				this.reminder.end_date = null;
			}
			this.$store
				.dispatch(POST, { url: `reminder`, data: this.reminder })
				.then(({ data }) => {
					this.$store.commit(SET_MESSAGE, [
						{ model: true, message: "Success ! Reminder added successfully." },
					]);
					this.$refs.form.reset();
					this.reminder.description = "";
					this.$emit("updateRemindSuccess", data);
					if (data) {
						this.$emit("deleteSuccess", data);
					}
					this.$emit("close", true);
				})
				.catch((error) => {
					console.log({ error });
				})
				.finally(() => {
					this.pageLoading = false;
				});
		},
		closeDrawer() {
			this.reminder.description = "";
			this.$refs.form.reset();
			this.$emit("close", true);
		},
	},
	computed: {
		...mapGetters(["errors", "currentUser", "userType", "isMaster"]),
	},
	mounted() {
		this.getRelatedField();
		this.getReminderSetting();

		//this.reminder.attendees = [this.userId];
	},
	beforeMount() {
		this.lead_id = this.$route.params.id;
	},
};
</script>

<style scoped></style>
