<template>
	<v-form ref="form">
		<div>
			<v-navigation-drawer v-model="drawer" fixed width="600" right temporary stateless>
				<div class="drawer-wrapper">
					<v-flex class="py-3 px-5 d-flex border-bottom drawer-header">
						<div class="font-level-3-bold">{{ titleStatus }}Note</div>
						<v-spacer></v-spacer>
						<v-btn depressed tile class="mr-2" v-on:click="closeDrawer()">Cancel</v-btn>
						<v-btn
							tile
							depressed
							color="white--text"
							class="blue darken-4"
							v-on:click="update_or_create()"
							>Save</v-btn
						>
					</v-flex>
					<div class="drawer-content pt-5 px-5">
						<ckeditor
							:editor="editor"
							v-model="create_note.description"
							:config="editorConfig"
						></ckeditor>
					</div>
				</div>
			</v-navigation-drawer>
		</div>
	</v-form>
</template>
<script>
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { PUT, POST } from "@/core/services/store/request.module";
import { SET_MESSAGE } from "@/core/services/store/common.module";

export default {
	name: "Address",
	props: {
		drawer: {
			type: Boolean,
			default: false,
		},
		getNoteData: {
			type: Object,
		},
		related: {
			type: String,
			default: null,
		},
		relatedId: {
			type: Number,
			default: 0,
		},
		titleStatus: {
			type: String,
		},
	},
	watch: {
		getNoteData: {
			handler(param) {
				this.create_note.description = param.description;
			},
		},
	},
	data() {
		return {
			editor: ClassicEditor,
			editorData: "<p>Create Notes</p>",
			editorConfig: {},
			noteId: null,
			create_note: {
				description: null,
				related_to: null,
				related_id: null,
			},
		};
	},
	methods: {
		update_or_create() {
			if (this.getNoteData && this.getNoteData.id) {
				this.updateNoteData();
			} else {
				this.saveNoteData();
			}
		},
		updateNoteData() {
			this.pageLoading = true;
			this.create_note.related_to = this.related;
			this.create_note.related_id = this.relatedId;
			this.$store
				.dispatch(PUT, {
					url: `note/${this.getNoteData.id}`,
					data: this.create_note,
				})
				.then(() => {
					this.$store.commit(SET_MESSAGE, [
						{ model: true, message: "Success ! Note updated successfully." },
					]);

					this.create_note.description = "";
					this.$emit("close", true);
					this.$emit("updateSuccess", true);
				})
				.catch((error) => {
					console.log({ error });
				})
				.finally(() => {
					this.pageLoading = false;
				});
		},
		saveNoteData() {
			this.pageLoading = true;
			this.create_note.related_to = this.related;
			this.create_note.related_id = this.relatedId;
			this.$store
				.dispatch(POST, { url: `note`, data: this.create_note })
				.then(() => {
					this.$store.commit(SET_MESSAGE, [
						{ model: true, message: "Success ! Note added successfully." },
					]);

					this.create_note.description = "";
					this.$emit("close", true);
					this.$emit("updateSuccess", true);
				})
				.catch((error) => {
					console.log({ error });
				})
				.finally(() => {
					this.pageLoading = false;
				});
		},
		closeDrawer() {
			this.create_note.description = "";
			this.$emit("close", true);
		},
	},
	beforeMount() {
		this.lead_id = this.$route.params.id;
	},
};
</script>
<style scoped></style>
