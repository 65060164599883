<template>
	<v-sheet class="main-detail">
		<div class="">
			<v-layout class="section-main-content">
				<div class="details-body">
					<div class="p-sticky bg-white">
						<v-layout class="px-4 py-2">
							<v-tabs
								v-model="assetTab"
								background-color="transparent"
								color="blue"
								class="custom-tab-transparent w-100"
								active-class="blue darken-4 text-white"
								hide-slider
							>
								<!-- <v-tab href="#notes"
									><v-icon small left>mdi-note-plus-outline</v-icon>Lead</v-tab
								>
								<v-tab  href="#file">
									<v-icon small left>mdi-file-document-multiple</v-icon>Customer
								</v-tab>
								<v-tab href="#task" v-on:click="getTasks()">
									<v-icon small left>mdi-repeat</v-icon>Customer</v-tab
								>
								<v-tab href="#meeting" v-on:click="getMeeting()">
									<v-icon small left>mdi-timeline-clock-outline</v-icon>Meeting</v-tab
								>
								<v-tab href="#reminder" v-on:click="getReminder()">
									<v-icon small left>mdi-alarm-check</v-icon>Reminder</v-tab
								> -->
								<v-tab href="#activity"><v-icon small left>mdi-wrench-outline</v-icon>All Activity</v-tab>

								<v-tab href="#Leadact" v-on:click="leadactivity('Leads')">
									<v-icon small left>mdi-note-plus-outline</v-icon>Leads</v-tab
								>
								<v-tab href="#CustomerType" v-on:click="custactivityfun('Customer')">
									<v-icon small left>mdi-file-document-multiple</v-icon>Customer</v-tab
								>

								<v-tab href="#TaskType" v-on:click="taskactivityfun('Task')">
									<v-icon small left>mdi-repeat</v-icon>Task</v-tab
								>

								<v-tab href="#Meetingtype" v-on:click="meetingactivitfun('Meeting')">
									<v-icon small left>mdi-file-document-multiple</v-icon>Meeting</v-tab
								>

								<v-tab href="#Holidaytype" v-on:click="Holidyactivityfun('Holiday')">
									<v-icon small left>mdi-file-document-multiple</v-icon>Holiday</v-tab
								>

								<v-tab href="#Leavetype" v-on:click="leaveactivityfunc('Leave')">
									<v-icon small left>mdi-file-document-multiple</v-icon>Leave</v-tab
								>

								<v-tab href="#ClaimType" v-on:click="claimactivityfunc('Claim')">
									<v-icon small left>mdi-alarm-check</v-icon>Claims</v-tab
								>

								<v-tab href="#projectType" v-on:click="Projectactivityfun('Project')">
									<v-icon small left>mdi-file-document-multiple</v-icon>Project</v-tab
								>

								<v-tab href="#contractType" v-on:click="Contractactivityfun('Contract')">
									<v-icon small left>mdi-file-document-multiple</v-icon>Contract</v-tab
								>

								<!-- <v-tab href="#recurring" v-on:click="recurractivityfunc('Recurring')">
									<v-icon small left>mdi-timeline-clock-outline</v-icon>Recurring</v-tab
								> -->
							</v-tabs>
						</v-layout>
					</div>
					<div class="tab-layout-content px-4">
						<v-tabs-items v-model="assetTab">
							<v-tab-item value="notes">
								<AllNotesTab
									:relatedId="lead_id"
									:relatedType="'lead-notes'"
									create-url="lead-note"
									get-url="lead-note"
								>
								</AllNotesTab>
							</v-tab-item>
							<v-tab-item value="file">
								<Files v-if="assetTab == 'file'" class="mx-4" type="lead" :url="'lead'" :type-id="lead_id">
								</Files>
							</v-tab-item>
							<v-tab-item value="task">
								<div v-if="taskData && taskData.length">
									<!-- <div class="py-3">
										<div class="fs-16 fw-500 primary--text"></div>
									</div> -->
									<div v-for="(tasks, index) in taskData" :key="index">
										<TaskTab
											v-on:singleTaskEdit="taskEdit($event)"
											v-on:editTitle="open_task_drawer($event)"
											:open_details="!index ? true : false"
											:task="tasks"
											:index-task="index"
											v-on:taskSuccess="refreshTask"
											v-on:priorityChange="refreshTask"
										></TaskTab>
									</div>
								</div>
								<template v-else>
									<p class="m-0 row-not-found text-center">
										<img :src="$assetURL('media/error/empty.png')" class="row-not-found-image" />
										Uhh... There are no task at the moment.
									</p>
								</template>
							</v-tab-item>
							<v-tab-item value="meeting">
								<div v-if="meetingData && meetingData.length">
									<div class="py-3">
										<div class="fs-16 fw-500 primary--text"></div>
									</div>
									<div v-for="(meetings, index) in meetingData" :key="index">
										<MeetingTab
											:open_details="!index ? true : false"
											v-on:editMeeting="editMeeting($event)"
											v-on:editTitle="open_meeting_drawer($event)"
											v-on:deleteMeeting="getMeeting"
											:index-meeting="index"
											:meeting="meetings"
											v-on:meetingSuccess="refreshMeeting"
										></MeetingTab>
									</div>
								</div>
								<template v-else>
									<p class="m-0 row-not-found text-center">
										<img :src="$assetURL('media/error/empty.png')" class="row-not-found-image" />
										Uhh... There are no meeting at the moment.
									</p>
								</template>
							</v-tab-item>

							<v-tab-item value="reminder">
								<div v-if="reminderData && reminderData.length">
									<!-- <div class="py-3">
										<div class="fs-16 fw-500 primary--text">April 28</div>
									</div> -->
									<div v-for="(reminder, index) in reminderData" :key="index">
										<ReminderTab
											v-on:editReminder="editReminderForm($event)"
											v-on:editTitle="open_reminder_drawer($event)"
											:open_details="!index ? true : false"
											:remind="reminder"
											:index-reminder="index"
											v-on:reminderSuccess="refreshReminder"
										></ReminderTab>
									</div>
								</div>
								<template v-else>
									<p class="m-0 row-not-found text-center">
										<img :src="$assetURL('media/error/empty.png')" class="row-not-found-image" />
										Uhh... There are no reminder at the moment.
									</p>
								</template>
							</v-tab-item>
							<v-tab-item value="activity">
								<div v-if="logData && logData.length">
									<div v-for="(logs, index) in logData" :key="index">
										<ActivityTab :open_details="!index ? true : false" :log="logs" title="Lead"></ActivityTab>
									</div>

									<!-- <ActivityTimelineView
										v-if="usersId"
										:related-id="usersId"
										module="User"
										:loaded="Loaded.activity"
										@loaded="Loaded.activity = $event"
										:inlineStyle="{ height: 'calc(100vh - 116px)' }"
									></ActivityTimelineView> -->
								</div>
								<template v-else>
									<p class="m-0 row-not-found text-center">
										<img :src="$assetURL('media/error/empty.png')" class="row-not-found-image" />
										Uhh... There are no activity at the moment.
									</p>
								</template>
							</v-tab-item>

							<v-tab-item value="Leadact">
								<div v-if="leadactdata && leadactdata.length">
									<div v-for="(leedlogs, index) in leadactdata" :key="index">
										<ActivityTab
											:open_details="!index ? true : false"
											:log="leedlogs"
											title="Lead"
										></ActivityTab>
									</div>
								</div>
								<template v-else>
									<p class="m-0 row-not-found text-center">
										<img :src="$assetURL('media/error/empty.png')" class="row-not-found-image" />
										Uhh... There are no activity at the moment.
									</p>
								</template>
							</v-tab-item>
							<v-tab-item value="CustomerType">
								<div v-if="custdata && custdata.length">
									<div v-for="(custlogs, index) in custdata" :key="index">
										<CustomerActivity
											:open_details="!index ? true : false"
											:log="custlogs"
											title="Customer"
										></CustomerActivity>
									</div>
								</div>
								<template v-else>
									<p class="m-0 row-not-found text-center">
										<img :src="$assetURL('media/error/empty.png')" class="row-not-found-image" />
										Uhh... There are no activity at the moment.
									</p>
								</template>
							</v-tab-item>
							<v-tab-item value="Meetingtype">
								<div v-if="meetdata && meetdata.length">
									<div v-for="(meetdata, index) in meetdata" :key="index">
										<Meetlogactivity
											:open_details="!index ? true : false"
											:log="meetdata"
											title="Meeting"
										></Meetlogactivity>
									</div>
								</div>
								<template v-else>
									<p class="m-0 row-not-found text-center">
										<img :src="$assetURL('media/error/empty.png')" class="row-not-found-image" />
										Uhh... There are no activity at the moment.
									</p>
								</template>
							</v-tab-item>
							<v-tab-item value="TaskType">
								<div v-if="taskdata && taskdata.length">
									<div v-for="(tasklogs, index) in taskdata" :key="index">
										<Taskactivity
											:open_details="!index ? true : false"
											:log="tasklogs"
											title="Task"
										></Taskactivity>
									</div>
								</div>
								<template v-else>
									<p class="m-0 row-not-found text-center">
										<img :src="$assetURL('media/error/empty.png')" class="row-not-found-image" />
										Uhh... There are no activity at the moment.
									</p>
								</template>
							</v-tab-item>

							<v-tab-item value="ClaimType">
								<div v-if="claimdata && claimdata.length">
									<div v-for="(claimlogs, index) in claimdata" :key="index">
										<ClaimActivity
											:open_details="!index ? true : false"
											:log="claimlogs"
											title="Claim"
										></ClaimActivity>
									</div>
								</div>
								<template v-else>
									<p class="m-0 row-not-found text-center">
										<img :src="$assetURL('media/error/empty.png')" class="row-not-found-image" />
										Uhh... There are no activity at the moment.
									</p>
								</template>
							</v-tab-item>
							<v-tab-item value="projectType">
								<div v-if="prjectdata && prjectdata.length">
									<div v-for="(claimlogs, index) in prjectdata" :key="index">
										<ProjectActivity
											:open_details="!index ? true : false"
											:log="claimlogs"
											title="Claim"
										></ProjectActivity>
									</div>
								</div>
								<template v-else>
									<p class="m-0 row-not-found text-center">
										<img :src="$assetURL('media/error/empty.png')" class="row-not-found-image" />
										Uhh... There are no activity at the moment.
									</p>
								</template>
							</v-tab-item>
							<v-tab-item value="contractType">
								<div v-if="contractdata && contractdata.length">
									<div v-for="(claimlogs, index) in contractdata" :key="index">
										<ContractActivity
											:open_details="!index ? true : false"
											:log="claimlogs"
											title="Claim"
										></ContractActivity>
									</div>
								</div>
								<template v-else>
									<p class="m-0 row-not-found text-center">
										<img :src="$assetURL('media/error/empty.png')" class="row-not-found-image" />
										Uhh... There are no activity at the moment.
									</p>
								</template>
							</v-tab-item>
							<!-- <v-tab-item value="recurring">
								<div v-if="recurrdata && recurrdata.length">
									<div v-for="(claimlogs, index) in recurrdata" :key="index">
										<RecurringActivity
											:open_details="!index ? true : false"
											:log="claimlogs"
											title="Recurring"
										></RecurringActivity>
									</div>
								</div>
								<template v-else>
									<p class="m-0 row-not-found text-center">
										<img :src="$assetURL('media/error/empty.png')" class="row-not-found-image" />
										Uhh... There are no activity at the moment.
									</p>
								</template>
							</v-tab-item> -->

							<v-tab-item value="Holidaytype">
								<div v-if="holidaydata && holidaydata.length">
									<div v-for="(holidaylogs, index) in holidaydata" :key="index">
										<Holidayactivity
											:open_details="!index ? true : false"
											:log="holidaylogs"
											title="Holiday"
										></Holidayactivity>
									</div>
								</div>
								<template v-else>
									<p class="m-0 row-not-found text-center">
										<img :src="$assetURL('media/error/empty.png')" class="row-not-found-image" />
										Uhh... There are no activity at the moment.
									</p>
								</template>
							</v-tab-item>

							<v-tab-item value="Leavetype">
								<div v-if="leavedata && leavedata.length">
									<div v-for="(leavelogs, index) in leavedata" :key="index">
										<LeaveActivity
											:open_details="!index ? true : false"
											:log="leavelogs"
											title="Leave"
										></LeaveActivity>
									</div>
								</div>
								<template v-else>
									<p class="m-0 row-not-found text-center">
										<img :src="$assetURL('media/error/empty.png')" class="row-not-found-image" />
										Uhh... There are no activity at the moment.
									</p>
								</template>
							</v-tab-item>
						</v-tabs-items>
					</div>
				</div>
				<div class="listing-sidebar border-left" v-if="false">
					<v-expansion-panels v-model="panel" multiple>
						<v-expansion-panel depressed elevation-0>
							<v-expansion-panel-header hide-actions class="py-0 pl-3 pr-1 expansion-header">
								<div class="position-relative">
									<v-icon> mdi-menu-right </v-icon>
									Companies ({{ companyData ? companyData.length : 0 }})
								</div>
								<v-spacer></v-spacer>
								<div class="text-right">
									<v-btn
										depressed
										tile
										class="blue darken-4 my-auto"
										color="white--text"
										@click.stop="open_dialog.add_company = true"
									>
										<v-icon>mdi-plus</v-icon>
										Add
									</v-btn>
								</div>
							</v-expansion-panel-header>
							<v-expansion-panel-content class="elevation-0">
								<div v-if="showComapny">
									<template v-for="(companyInfo, index) in companyData">
										<div v-if="index <= 1" :key="index" class="expansion-content border-gray pa-0 pr-1 mb-2">
											<div class="pl-3 pr-0 py-1 position-relative">
												<v-menu offset-y left tile>
													<template v-if="companyInfo.primary < 1" v-slot:activator="{ on, attrs }">
														<v-icon v-bind="attrs" v-on="on" size="18" style="position: absolute; right: 0px"
															>mdi-dots-vertical</v-icon
														>
													</template>
													<v-list>
														<v-list-item-group>
															<v-list-item>
																<v-list-item-title
																	class="text-capitalize"
																	v-on:click="companyMarkPrimary(companyInfo.id)"
																	>Set Primary</v-list-item-title
																>
															</v-list-item>
														</v-list-item-group>
													</v-list>
												</v-menu>
												<div class="pb-5" v-if="companyInfo.primary > 0">
													<div class="lead-type-label border-bottom-gray border-right-gray">Primary</div>
												</div>
												<div class="primary--text" v-if="companyInfo">{{ companyInfo.company_name }}</div>
												<div class="primary--text" v-if="companyInfo">
													{{ companyInfo.company_domain_name }}
												</div>
												<div class="" v-if="companyInfo">{{ companyInfo.phone }}</div>
											</div>
										</div>
									</template>
								</div>
								<div v-if="hideCompany">
									<template v-for="(companyInfo, index) in companyData">
										<div :key="index" class="expansion-content border-gray pa-0 pr-1 mb-2">
											<div class="pl-3 pr-0 py-1 position-relative">
												<v-menu offset-y left tile>
													<template v-if="companyInfo.primary < 1" v-slot:activator="{ on, attrs }">
														<v-icon v-bind="attrs" v-on="on" size="18" style="position: absolute; right: 0px"
															>mdi-dots-vertical</v-icon
														>
													</template>
													<v-list>
														<v-list-item-group>
															<v-list-item>
																<v-list-item-title
																	class="text-capitalize"
																	v-on:click="companyMarkPrimary(companyInfo.id)"
																	>Set Primary</v-list-item-title
																>
															</v-list-item>
														</v-list-item-group>
													</v-list>
												</v-menu>
												<div class="pb-5" v-if="companyInfo.primary > 0">
													<div class="lead-type-label border-bottom-gray border-right-gray">Primary</div>
												</div>
												<div class="primary--text" v-if="companyInfo">{{ companyInfo.company_name }}</div>
												<div class="primary--text" v-if="companyInfo && companyInfo.company_domain_name">
													{{ companyInfo.company_domain_name }}
												</div>
												<div class="" v-if="companyInfo && companyInfo.phone">{{ companyInfo.phone }}</div>
											</div>
										</div>
									</template>
								</div>
								<!-- <div v-else>
									<div class="mt-1 pl-8 primary--text fw-600 cursor-pointer">No Company</div>
								</div> -->
								<!-- <div
									v-if="showComapny"
									class="mt-1 primary--text fw-600 cursor-pointer"
									v-on:click="showHideCompany()"
								>
									View All
								</div>
								<div
									v-if="hideCompany"
									class="mt-1 primary--text fw-600 cursor-pointer"
									v-on:click="showLessCompany()"
								>
									Show Less
								</div> -->
							</v-expansion-panel-content>
						</v-expansion-panel>
						<v-expansion-panel>
							<v-expansion-panel-header hide-actions class="py-0 pl-3 pr-1 expansion-header">
								<div class="position-relative">
									<v-icon> mdi-menu-right </v-icon>
									Deals ({{ dealData ? dealData.length : 0 }})
								</div>
								<v-spacer></v-spacer>
								<div class="text-right">
									<v-btn
										depressed
										tile
										class="blue darken-4 my-auto"
										color="white--text "
										@click.stop="open_dialog.add_deal = true"
									>
										<v-icon>mdi-plus</v-icon>
										Add
									</v-btn>
								</div>
							</v-expansion-panel-header>
							<v-expansion-panel-content>
								<div v-if="showDeal">
									<template v-for="(dealInfo, index) in dealData">
										<div v-if="index <= 1" :key="index" class="expansion-content border-gray pa-0 pr-1">
											<div class="pl-3 pr-0 py-1 position-relative">
												<div class="primary--text" v-if="dealInfo">{{ dealInfo.deal_name }}</div>
												<div class="">
													<span class="fw-500">Amounts :</span>
													<span v-if="dealInfo"> {{ dealInfo.amount }} </span>
												</div>
												<div class="">
													<span class="fw-500">Close date :</span
													><span v-if="dealInfo"> {{ dealInfo.close_date }}</span>
												</div>
												<div class="">
													<span class="fw-500">Stage :</span>
													<v-chip class="ma-2" color="green white--text" x-small v-if="dealInfo">
														{{ dealInfo.deal_stage }}
													</v-chip>
												</div>
											</div>
										</div>
									</template>
								</div>
								<div v-if="hideDeal">
									<template v-for="(dealInfo, index) in dealData">
										<div :key="index" class="expansion-content border-gray pa-0 pr-1">
											<div class="pl-3 pr-0 py-1 position-relative">
												<div class="primary--text" v-if="dealInfo">{{ dealInfo.deal_name }}</div>
												<div class="">
													<span class="fw-500">Amounts :</span>
													<span v-if="dealInfo"> {{ dealInfo.amount }} </span>
												</div>
												<div class="">
													<span class="fw-500">Close date :</span
													><span v-if="dealInfo"> {{ dealInfo.close_date }}</span>
												</div>
												<div class="">
													<span class="fw-500">Stage :</span>
													<v-chip class="ma-2" color="green white--text" x-small v-if="dealInfo">
														{{ dealInfo.deal_stage }}
													</v-chip>
												</div>
											</div>
										</div>
									</template>
								</div>

								<!-- <div
									v-if="showDeal"
									class="mt-1 primary--text fw-600 cursor-pointer"
									v-on:click="showHideDeal()"
								>
									View All
								</div>
								<div
									v-if="hideDeal"
									class="mt-1 primary--text fw-600 cursor-pointer"
									v-on:click="showLessDeal()"
								>
									Show Less
								</div> -->
							</v-expansion-panel-content>
						</v-expansion-panel>
						<!-- <v-expansion-panel>
							<v-expansion-panel-header hide-actions class="py-0 pl-3 pr-1 expansion-header">
								<div class="position-relative">
									<v-icon> mdi-menu-right </v-icon>
									Attachments ({{ attachmentData ? attachmentData.length : 0 }})
								</div>
								<v-spacer></v-spacer>
								<div class="text-right">
									<v-btn
										depressed
										tile
										class="blue darken-4 my-auto"
										color="white--text "
										@click.stop="uploadDialog()"
									>
										<v-icon>mdi-plus</v-icon>
										Add
									</v-btn>
								</div>
							</v-expansion-panel-header>
							<v-expansion-panel-content>
								<div v-if="attachmentData">
									<div v-if="showAttach">
										<div class="expansion-content pa-0">
											<template v-for="(attachmentInfo, index) in attachmentData">
												<div class="attachment-list border-gray mb-1" :key="index">
													<div class="d-flex py-1 px-2">
														<span class="icon">
															<v-icon
																class="mdi-16px mr-1 primary--text"
																v-on:click="downloadAttachment(attachmentInfo.url)"
																>mdi-download</v-icon
															>
														</span>
														<span
															v-if="attachmentInfo && attachmentInfo.name"
															class="ellipsis primary--text"
															style="max-width: 120px"
														>
															{{ attachmentInfo.name }}</span
														>&nbsp;
														<span class="size" v-if="attachmentInfo && attachmentInfo.size">
															({{ attachmentInfo.size }})</span
														>
														<v-spacer></v-spacer>
													</div>
												</div>
											</template>
										</div>
									</div>
									<div v-if="hideAttach">
										<div class="expansion-content pa-0">
											<template v-for="(attachmentInfo, index) in attachmentData">
												<div class="attachment-list border-gray mb-1" :key="index">
													<div class="d-flex py-1 px-2">
														<span class="icon">
															<v-icon
																class="mdi-16px mr-1 primary--text"
																v-on:click="downloadAttachment(attachmentInfo.url)"
																>mdi-download</v-icon
															>
														</span>
														<span
															v-if="attachmentInfo && attachmentInfo.name"
															class="ellipsis primary--text"
															style="max-width: 120px"
														>
															{{ attachmentInfo.name }}</span
														>&nbsp;
														<span v-if="attachmentInfo && attachmentInfo.size" class="size">
															({{ attachmentInfo.size }})</span
														>
														<v-spacer></v-spacer>
													</div>
												</div>
											</template>
										</div>
									</div>
									
								</div>
							</v-expansion-panel-content>
						</v-expansion-panel> -->
						<!-- <v-expansion-panel>
							<v-expansion-panel-header hide-actions class="py-0 px-3 expansion-header">
								<div class="position-relative">
									<v-icon> mdi-menu-right </v-icon>
									Quotations (4)
								</div>
							</v-expansion-panel-header>
							<v-expansion-panel-content>
								<div class="expansion-content border-gray pa-0">
									<div class="pl-3 pr-0 py-1 position-relative">
										<div class="primary--text">Bthrust - New quotions</div>
										<div class=""><span class="fw-500">Amounts :</span> - -</div>
										<div class=""><span class="fw-500">Close date :</span> April 30, 2022</div>
										<div class=""><span class="fw-500">Stage :</span> Appointment scheduled</div>
									</div>
								</div>
								<div class="mt-1 primary--text fw-600 cursor-pointer">View All</div>
							</v-expansion-panel-content>
						</v-expansion-panel>
						<v-expansion-panel>
							<v-expansion-panel-header hide-actions class="py-0 px-3 expansion-header">
								<div class="position-relative">
									<v-icon> mdi-menu-right </v-icon>
									Tickets (3)
								</div>
							</v-expansion-panel-header>
							<v-expansion-panel-content>
								<div class="expansion-content border-gray pa-0">
									<div class="pl-3 pr-0 py-1 position-relative">
										<div class="primary--text">Bthrust - New Tickets</div>
										<div class=""><span class="fw-500">Amounts :</span> - -</div>
										<div class=""><span class="fw-500">Close date :</span> April 30, 2022</div>
										<div class=""><span class="fw-500">Stage :</span> Appointment scheduled</div>
									</div>
								</div>
								<div class="mt-1 primary--text fw-600 cursor-pointer">View All</div>
							</v-expansion-panel-content>
						</v-expansion-panel> -->
					</v-expansion-panels>
				</div>
			</v-layout>
			<v-file-input
				multiple
				ref="uploadFile"
				class="d-none"
				v-on:change="uploadFile($event)"
			></v-file-input>
		</div>
		<!-- sidebar drawers -->
		<CreateNote
			:drawer="open_dialog.notes"
			:title-status="meeting_title"
			v-on:close="open_dialog.notes = false"
			:get-note-data="editNoteData"
			v-on:updateSuccess="updateSuccessNote"
			related="lead"
			:related-id="lead_id"
		></CreateNote>
		<CreateTask
			v-if="open_dialog.task"
			:drawer="open_dialog.task"
			:title-status="meeting_title"
			:task-data="editTaskData"
			:attendies="attendieList"
			v-on:updateTask="refreshTask"
			v-on:close="open_dialog.task = false"
			related="lead"
			:related-id="lead_id"
		></CreateTask>
		<CreateMeeting
			v-if="open_dialog.meeting"
			:drawer="open_dialog.meeting"
			:title-status="meeting_title"
			:get-meeting-data="editMeetingData"
			:attendies="attendieList"
			v-on:updateMeetingSuccess="refreshMeeting"
			v-on:close="open_dialog.meeting = false"
			related="lead"
			:related-id="lead_id"
		></CreateMeeting>
		<CreateReminder
			v-if="open_dialog.reminder"
			:drawer="open_dialog.reminder"
			:title-status="meeting_title"
			:reminder-single-data="editReminderData"
			:attendies="attendieList"
			v-on:updateRemindSuccess="refreshReminder"
			v-on:close="open_dialog.reminder = false"
			related="lead"
			:related-id="lead_id"
		></CreateReminder>
		<AddCompany
			v-on:updateCompanyList="getCompanyList"
			:drawer="open_dialog.add_company"
			v-on:close="open_dialog.add_company = false"
		></AddCompany>
		<AddDeal
			v-on:updateDealList="getCompanyDealList"
			:drawer="open_dialog.add_deal"
			v-on:close="open_dialog.add_deal = false"
		></AddDeal>
		<CreateLead :drawer="drawerCreate" v-on:close="drawerCreate = false"></CreateLead>
		<add-dialog
			:document-dialog="openUploadModel"
			v-on:close="openUploadModel = false"
			v-on:success="getLeadAttachment"
			:related-id="lead_id"
			:related-type="'lead'"
		></add-dialog>
	</v-sheet>
</template>
<script>
import Files from "@/view/components/Files";
import CreateLead from "@/view/pages/leads/create/CreateLead";
import CreateNote from "@/view/pages/leads/create/CreateNote";
import CreateTask from "@/view/pages/leads/create/CreateTask";
import CreateMeeting from "@/view/pages/leads/create/CreateMeeting";
import CreateReminder from "@/view/pages/leads/create/CreateReminder";
import AddCompany from "@/view/pages/leads/create/AddCompany";
import AddDeal from "@/view/pages/leads/create/AddDeal";
import ActivityTab from "@/view/pages/leads/components/ActivityTab";
import Taskactivity from "@/view/pages/leads/components/Taskactivity";
import CustomerActivity from "@/view/pages/leads/components/CustomerActivity";
import Meetlogactivity from "@/view/pages/leads/components/Meetlogactivity";
import ProjectActivity from "@/view/pages/leads/components/ProjectActivity";
import ContractActivity from "@/view/pages/leads/components/ContractActivity";
import ClaimActivity from "@/view/pages/leads/components/ClaimActivity";
/* import ActivityTimelineView from "@/view/components/ActivityTimelineView.vue"; */

/* import RecurringActivity from "@/view/pages/leads/components/RecurringActivity"; */
import LeaveActivity from "@/view/pages/leads/components/LeaveActivity";
import Holidayactivity from "@/view/pages/leads/components/Holidayactivity";

//import NotesTab from "@/view/pages/leads/components/NotesTab";
import TaskTab from "@/view/pages/leads/components/TaskTab";
import MeetingTab from "@/view/pages/leads/components/MeetingTab";
import ReminderTab from "@/view/pages/leads/components/ReminderTab";
import AddDialog from "@/view/pages/leads/create/AddDialog";
import { GET, QUERY, PATCH } from "@/core/services/store/request.module";
import { SET_MESSAGE } from "@/core/services/store/common.module";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import AllNotesTab from "@/view/pages/leads/components/NotesTab3";

import { toSafeInteger } from "lodash";

export default {
	name: "Leads-details",
	data() {
		return {
			datavalue: null,
			meeting_title: null,
			hideCompany: false,
			showComapny: true,
			hideDeal: false,
			showDeal: true,
			hideAttach: false,
			showAttach: true,
			openUploadModel: false,
			search_open: false,
			lead_id: 0,
			leadData: {},
			noteData: null,
			taskData: [],
			recurrdata: [],
			claimdata: [],
			meetdata: [],
			holidaydata: [],
			custdata: [],
			leavedata: [],
			leadactdata: [],
			taskdata: null,
			meetingData: [],
			prjectdata: [],
			contractdata: [],
			reminderData: null,
			logData: null,
			meedata: null,
			companyData: null,
			dealData: null,
			attachmentData: [],
			editNoteData: {},
			editMeetingData: null,
			editReminderData: {},
			editTaskData: null,
			users: null,
			relatedType: "lead",
			attendieList: {},
			allCompanyLIst: false,
			open_dialog: {
				notes: false,
				task: false,
				meeting: false,
				reminder: false,
				add_company: false,
				add_deal: false,
				add_attachment: false,
			},
			panel: [0],
			drawerCreate: false,
			assetTab: "notes",
			task_action: false,
			open_details: false,
			assign_note: "Add Description",
			file_list: [],
		};
	},
	components: {
		CreateLead,
		/* 		ActivityTimelineView, */
		CreateNote,
		CreateTask,
		CreateMeeting,
		CreateReminder,
		ProjectActivity,
		ContractActivity,
		AddCompany,
		AddDeal,
		ActivityTab,
		//NotesTab,
		TaskTab,
		MeetingTab,
		ReminderTab,
		AddDialog,
		AllNotesTab,
		Files,
		Taskactivity,
		CustomerActivity,
		Meetlogactivity,
		ClaimActivity,
		/* RecurringActivity, */
		LeaveActivity,
		Holidayactivity,
	},
	methods: {
		routeToUpdate(id) {
			this.$router.push({
				name: "update-lead",
				params: { id },
			});
		},
		manageCreateNotes() {
			this.editNoteData = {};
			this.open_dialog.notes = true;
		},
		covertCustomer() {
			const _this = this;
			_this.$router.push({
				name: "customer-create",
				query: {
					lead_id: _this.lead_id,
				},
			});
		},
		open_meeting_drawer(name) {
			this.open_dialog.meeting = true;
			this.meeting_title = name;
		},
		open_task_drawer(name) {
			this.editTaskData = {};
			this.attendieList = {};
			this.open_dialog.task = true;
			this.meeting_title = name;
		},

		open_note_drawer(name) {
			this.open_dialog.notes = true;
			this.meeting_title = name;
		},
		open_reminder_drawer(name) {
			this.editReminderData = {};
			this.attendieList = {};
			this.open_dialog.reminder = true;
			this.meeting_title = name;
		},
		showHideCompany() {
			this.hideCompany = true;
			this.showComapny = false;
		},
		showLessCompany() {
			this.hideCompany = false;
			this.showComapny = true;
		},
		showHideDeal() {
			this.hideDeal = true;
			this.showDeal = false;
		},
		showLessDeal() {
			this.hideDeal = false;
			this.showDeal = true;
		},
		showHideAttach() {
			this.hideAttach = true;
			this.showAttach = false;
		},
		showLessAttach() {
			this.hideAttach = false;
			this.showAttach = true;
		},
		updateSuccessNote() {
			this.open_dialog.notes = false;
			this.getNotes();
		},
		refreshMeeting() {
			this.getMeeting();
		},
		refreshReminder() {
			this.getReminder();
		},
		refreshTask() {
			this.getTasks();
		},
		editNote(id) {
			this.editNoteData = {};
			if (id > 0) {
				this.open_dialog.notes = true;
				this.$store
					.dispatch(GET, { url: `single-note/${id}` })
					.then((data) => {
						this.editNoteData = data;
					})
					.catch((error) => {
						console.log({ error });
					});
			}
		},
		taskEdit(id) {
			this.open_dialog.task = true;
			this.$store
				.dispatch(GET, { url: `single-task/${id}` })
				.then((data) => {
					this.editTaskData = data.tasks;
					this.attendieList = data.attendie[0];
				})
				.catch((error) => {
					console.log({ error });
				});
		},
		editReminderForm(id) {
			this.open_dialog.reminder = true;
			this.$store
				.dispatch(GET, { url: `single-reminder/${id}/lead` })
				.then((data) => {
					this.editReminderData = data.reminder;
					this.attendieList = data.attendie[0];
				})
				.catch((error) => {
					console.log({ error });
				});
		},
		editMeeting(id) {
			this.open_dialog.meeting = true;
			this.$store
				.dispatch(GET, { url: `single-meeting/${id}` })
				.then((data) => {
					this.editMeetingData = data.meeting;
					this.attendieList = data.attendie[0];
				})
				.catch((error) => {
					console.log({ error });
				});
		},
		pageTitle() {
			return "Lead Details";
		},
		uploadFile(data) {
			const file_size = (data.size / 1024).toFixed(1) + "kb";
			const data_opj = { icon: "mdi-file-check-outline", name: data.name, size: file_size };
			this.file_list.push(data_opj);
		},
		uploadDialog() {
			this.openUploadModel = true;
		},
		remove_file(index) {
			this.file_list.splice(index, 1);
		},
		singleLead() {
			this.$store
				.dispatch(GET, { url: `lead/${this.lead_id}` })
				.then((data) => {
					this.leadData = data.lead;
				})
				.catch((error) => {
					console.log({ error });
				});
		},
		getNotes() {
			this.editNoteData = {};
			this.$store
				.dispatch(QUERY, { url: `note`, data: { related_id: this.lead_id, related_to: "lead" } })
				.then((data) => {
					this.noteData = data.rows;
				})
				.catch((error) => {
					console.log({ error });
				});
		},
		leadactivity(datavalue) {
			this.$store
				.dispatch(QUERY, { url: `task-act`, data: { related_to: datavalue } })
				.then((data) => {
					this.leadactdata = data;
				})
				.catch((error) => {
					console.log({ error });
				});
		},
		custactivityfun(datavalue) {
			this.$store
				.dispatch(QUERY, { url: `task-act`, data: { related_to: datavalue } })
				.then((data) => {
					this.custdata = data;
				})
				.catch((error) => {
					console.log({ error });
				});
		},
		taskactivityfun(datavalue) {
			this.$store
				.dispatch(QUERY, { url: `task-act`, data: { related_to: datavalue } })
				.then((data) => {
					this.taskdata = data;
				})
				.catch((error) => {
					console.log({ error });
				});
		},
		meetingactivitfun(datavalue) {
			this.$store
				.dispatch(QUERY, { url: `task-act`, data: { related_to: datavalue } })
				.then((data) => {
					this.meetdata = data;
				})
				.catch((error) => {
					console.log({ error });
				});
		},

		claimactivityfunc(datavalue) {
			this.$store
				.dispatch(QUERY, { url: `task-act`, data: { related_to: datavalue } })
				.then((data) => {
					this.claimdata = data;
				})
				.catch((error) => {
					console.log({ error });
				});
		},
		Contractactivityfun(datavalue) {
			this.$store
				.dispatch(QUERY, { url: `task-act`, data: { related_to: datavalue } })
				.then((data) => {
					this.contractdata = data;
				})
				.catch((error) => {
					console.log({ error });
				});
		},
		Projectactivityfun(datavalue) {
			this.$store
				.dispatch(QUERY, { url: `task-act`, data: { related_to: datavalue } })
				.then((data) => {
					this.prjectdata = data;
				})
				.catch((error) => {
					console.log({ error });
				});
		},
		recurractivityfunc(datavalue) {
			this.$store
				.dispatch(QUERY, { url: `task-act`, data: { related_to: datavalue } })
				.then((data) => {
					this.recurrdata = data;
				})
				.catch((error) => {
					console.log({ error });
				});
		},
		/* Contractactivityfun(datavalue) {
			this.$store
			
				.dispatch(QUERY, { url: `task-act`, data: { related_to: datavalue } })
				.then((data) => {
					this.contractdata = data;
				})
				.catch((error) => {
					console.log({ error });
				});
		}, */
		leaveactivityfunc(datavalue) {
			this.$store
				.dispatch(QUERY, { url: `task-act`, data: { related_to: datavalue } })
				.then((data) => {
					this.leavedata = data;
				})
				.catch((error) => {
					console.log({ error });
				});
		},
		Holidyactivityfun(datavalue) {
			this.$store
				.dispatch(QUERY, { url: `task-act`, data: { related_to: datavalue } })
				.then((data) => {
					this.holidaydata = data;
				})
				.catch((error) => {
					console.log({ error });
				});
		},

		getTasks() {
			this.$store
				.dispatch(QUERY, { url: `task-lead`, data: { related_to: "lead", leadId: this.lead_id } })
				.then((data) => {
					this.taskData = data;
					/* this.open_dialog.task = false; */
				})
				.catch((error) => {
					console.log({ error });
				});
		},
		getMeeting() {
			this.$store
				.dispatch(QUERY, { url: `meeting-lead`, data: { related_to: "lead", leadId: this.lead_id } })
				.then((data) => {
					this.meetingData = data;
					this.open_dialog.reminder = false;
				})
				.catch((error) => {
					console.log({ error });
				});
		},
		getReminder() {
			this.$store
				.dispatch(QUERY, { url: `reminder`, data: { related_id: this.lead_id, related_to: "lead" } })
				.then((data) => {
					this.reminderData = data.rows;
					this.singleLead();
				})
				.catch((error) => {
					console.log({ error });
				});
		},
		getActivity() {
			this.$store
				.dispatch(GET, { url: `all-log` })
				.then((data) => {
					this.logData = data;
				})
				.catch((error) => {
					console.log({ error });
				});
		},
		getCompanyList() {
			this.$store
				.dispatch(GET, { url: `lead/${this.lead_id}/company` })
				.then((data) => {
					this.companyData = data.rows;
				})
				.catch((error) => {
					console.log({ error });
				});
		},
		getCompanyDealList() {
			this.$store
				.dispatch(GET, { url: `lead/${this.lead_id}/company-deal` })
				.then((data) => {
					this.dealData = data.rows;
				})
				.catch((error) => {
					console.log({ error });
				});
		},
		getLeadAttachment() {
			this.$store
				.dispatch(GET, { url: `lead/${this.lead_id}/${this.relatedType}/attachment` })
				.then((data) => {
					this.attachmentData = data;
				})
				.catch((error) => {
					console.log({ error });
				});
		},
		downloadAttachment(url) {
			window.open(url, "_blank");
		},
		companyMarkPrimary(id) {
			this.$store
				.dispatch(PATCH, { url: `lead/${this.lead_id}/mark-primary/${id}` })
				.then(() => {
					this.$store.commit(SET_MESSAGE, [
						{ model: true, message: "Success ! Company marked as primary." },
					]);
					this.getCompanyList();
				})
				.catch((error) => {
					console.log({ error });
				});
		},
		routeToUser() {
			this.$router.push("/users");
		},
	},
	mounted() {
		/* 	this.singleLead(); */
		this.getActivity();
		/* this.getCompanyList();
		this.getCompanyDealList();
		this.getLeadAttachment(); */

		this.$store.dispatch(SET_BREADCRUMB, [
			{ text: "Dashboard", disabled: false, href: "/dashboard" },
			{ text: "Activity", disabled: false, href: "/activity" },
		]);
	},
	beforeMount() {
		this.lead_id = toSafeInteger(this.$route.params.id);
	},
};
</script>
