<template>
	<div class="tab-card pa-3 mb-3 border tab-custom-data" :class="open_details ? 'border-ccc' : ''">
		<div
			class="d-flex"
			@click="open_details = !open_details"
			:class="open_details ? 'pb-2 border-bottom' : ''"
		>
			<div class="">
				<v-icon link class="mmr-2">{{ open_details ? "mdi-menu-down" : "mdi-menu-right" }}</v-icon>
				<span class="fs-500 fw-600" v-if="task">{{ task.title }}</span>
				assigned to
				<template v-if="task">
					<v-chip
						class="mb-1 mr-1 fw-500"
						label
						color="blue white--text"
						x-small
						v-for="(staff, index) in task.attendies"
						:key="index"
					>
						<template v-if="staff && staff.name">{{ staff.name }}</template>
					</v-chip>
				</template>
				<template v-if="this.task && this.task.barcode">
					<v-chip
						class="mr-1 text-capitalize"
						color="cyan white--text"
						x-small
						v-if="this.task && this.task.barcode"
						>{{ this.task.barcode }}
					</v-chip>
				</template>
				<template v-if="task && task.related_to">
					<v-chip class="mr-1 fw-500" label color="red white--text" x-small>
						{{ task.related_to }}
					</v-chip>
				</template>
			</div>
			<v-spacer></v-spacer>
			<div class="date d-flex align-items-center">
				<v-icon v-if="!open_details" class="mdi-16px mr-1 primary--text"
					>mdi-calendar-clock-outline</v-icon
				>
				<v-menu v-else offset-y left content-class="custom-menu" max-width="130px">
					<template v-slot:activator="{ on, attrs }">
						<div class="primary--text" v-bind="attrs" v-on="on">
							<span class="fs-500 fw-600">Actions </span>
							<v-icon link class="mmr-2 primary--text">mdi-menu-down</v-icon>
						</div>
					</template>
					<v-list tile>
						<v-list-item-group>
							<v-list-item v-if="indexTask > 0">
								<v-list-item-title v-on:click="taskPinned(task.id)">Pin</v-list-item-title>
							</v-list-item>
							<v-list-item>
								<v-list-item-title v-on:click="taskEdit(task.id, 'Update')">Edit</v-list-item-title>
							</v-list-item>
							<!-- <v-list-item>
								<v-list-item-title v-on:click="taskDelete(task)">Delete</v-list-item-title>
							</v-list-item> -->
						</v-list-item-group>
					</v-list>
				</v-menu>
				<span>Date : {{ formatDate(task.start_date) }}</span>
			</div>
		</div>
		<div v-if="open_details" v-on:click="routeToDetail(task.id)">
			<ul class="ul d-flex border-top py-3">
				<li class="mb-1 d-flex w-25">
					<div>
						<span class="fs-500 fw-400">Due date</span>
						<v-menu else offset-y right content-class="custom-menu">
							<template v-slot:activator="{ on, attrs }">
								<div class="primary--text" v-bind="attrs" v-on="on">
									<span class="fs-500 fw-600" v-if="task"> {{ formatDate(task.due_date) }}</span>
									<v-icon link class="primary--text">mdi-menu-down</v-icon>
								</div>
							</template>
							<v-list tile>
								<v-list-item-group>
									<v-list-item>
										<v-list-item-title>Current ({{ dueDate() }})</v-list-item-title>
									</v-list-item>

									<v-list-item v-on:change="updateDueDate(task.id, weekDateValue())">
										<v-list-item-title> {{ weekDate() }}</v-list-item-title>
									</v-list-item>
									<v-list-item v-on:change="updateDueDate(task.id, monthDateValue())">
										<v-list-item-title> {{ monthDate() }}</v-list-item-title>
									</v-list-item>
									<v-list-item v-on:change="updateDueDate(task.id, threeMonthValue())">
										<v-list-item-title> {{ threeMonth() }}</v-list-item-title>
									</v-list-item>
								</v-list-item-group>
							</v-list>
						</v-menu>
					</div>
				</li>
				<!-- <v-spacer></v-spacer> -->
				<li class="mb-1 d-flex w-25">
					<div>
						<span class="fs-500 fw-400">Priority</span>
						<v-menu else offset-y right content-class="custom-menu">
							<template v-slot:activator="{ on, attrs }">
								<div
									:class="`${
										task.priority == 'low'
											? 'cyan'
											: task.priority == 'medium'
											? 'green'
											: task.priority == 'high'
											? 'orange'
											: task.priority == 'urgent'
											? 'blue'
											: 'red'
									}--text`"
									v-bind="attrs"
									v-on="on"
								>
									<v-icon
										class="mdi-18px"
										:color="
											task.priority == 'low'
												? 'cyan'
												: task.priority == 'medium'
												? 'green'
												: task.priority == 'high'
												? 'orange'
												: task.priority == 'urgent'
												? 'blue'
												: 'red'
										"
										>mdi-circle</v-icon
									>
									<span class="fs-500 fw-600" v-if="task">{{ task.priority }}</span>
									<v-icon
										link
										:class="`${
											task.priority == 'low'
												? 'cyan'
												: task.priority == 'medium'
												? 'green'
												: task.priority == 'high'
												? 'orange'
												: task.priority == 'urgent'
												? 'blue'
												: 'red'
										}--text`"
										>mdi-menu-down</v-icon
									>
								</div>
							</template>
							<v-list tile>
								<v-list-item-group>
									<template v-for="(priority, index) in priorityList">
										<v-list-item :key="index" v-on:change="updatePriority(task.id, priority.value)">
											<v-list-item-title class="primary--text" v-if="priority" :value="priority.value">
												<v-icon
													class="mdi-18px"
													:color="
														priority.value == 'low'
															? 'cyan'
															: priority.value == 'medium'
															? 'green'
															: priority.value == 'high'
															? 'orange'
															: priority.value == 'urgent'
															? 'blue'
															: 'red'
													"
													>mdi-circle</v-icon
												>
												{{ priority.text }}</v-list-item-title
											>
										</v-list-item>
									</template>
								</v-list-item-group>
							</v-list>
						</v-menu>
					</div>
				</li>
				<!-- <v-spacer></v-spacer> -->
				<li class="mb-1 d-flex w-25">
					<div>
						<span class="fs-500 fw-400">Assign to </span>
						<template v-for="(data, index) in task.attendees">
							<div :key="index">
								<!-- <v-avatar left>
									<img :src="data.attendees_id.profile_img" />
										</v-avatar> -->
								<v-chip class="mb-1 mr-1" small
									><v-avatar left small>
										<img v-if="data.attendees_id.profile_img" :src="data.attendees_id.profile_img" />
										<img v-else src="https://ams-dev.businessthrust.com//media/misc/no_photo_found.png" />
									</v-avatar>
									<span v-if="data && data.attendees_id && data.attendees_id.display_name">{{
										data.attendees_id.display_name
									}}</span>
								</v-chip>
								<!-- <b>{{ data.attendees_id.first_name }}</b> -->
							</div>
						</template>
						<span class="fw-800" style="font-weight: 700" v-if="unassignUserList > 0">
							+{{ unassignUserList }} More</span
						>
						<v-menu else offset-y left content-class="custom-menu">
							<template v-slot:activator="{ on, attrs }">
								<div class="primary--text" v-bind="attrs" v-on="on">
									<span class="fs-500 fw-600" v-if="task">{{ task.display_name }}</span>
									<v-icon link class="primary--text">mdi-menu-down</v-icon>
								</div>
							</template>
							<v-list tile>
								<v-list-item-group>
									<template v-for="(user, index) in userList">
										<v-list-item :key="index" v-on:change="userUpdate(task.id, user.id)">
											<v-list-item-avatar>
												<img v-if="user.profile_img" :src="user.profile_img" />
												<img v-else src="https://ams-dev.businessthrust.com//media/misc/no_photo_found.png" />
											</v-list-item-avatar>
											<v-list-item-title v-if="user">{{ user.display_name }} </v-list-item-title>
										</v-list-item>
									</template>
								</v-list-item-group>
							</v-list>
						</v-menu>
					</div>
				</li>
				<li class="mb-1 d-flex w-25">
					<div>
						<span class="fs-500 fw-400 ml-5">Checklist</span>
						<div class="py-2 pt-2">
							<div class="px-5 d-flex flex-column" style="">
								<table class="w-100 note-table" v-if="checklists && checklists.length">
									<tr v-for="(row, index) in checklists" :key="index" class="blue lighten-5">
										<template v-if="index < 3">
											<td valign="top" width="2%">
												<v-checkbox
													hide-details
													readonly
													v-model="checklists[index].check"
													class="ma-1"
													color="blue"
												></v-checkbox>
											</td>
											<td class="" width="85%">
												<v-textarea
													class="note-area border-0"
													name="input-7-1"
													filled
													auto-grow
													readonly
													hide-details
													rows="1"
													:disabled="checklists[index].check"
													:ref="`textarea-${index}`"
													v-model="row.notes_remark"
												>
												</v-textarea>
											</td>
										</template>
									</tr>
								</table>
								<template v-else>
									<!-- <p class="m-0 row-not-found text-center">
										<img :src="$assetURL('media/error/empty.png')" class="row-not-found-image" />
										Uhh... There are no checklist at the moment.
									</p> -->
									<em class="text-muted">no checklist</em>
								</template>
							</div>
						</div>
					</div>
				</li>
			</ul>
			<!-- <div v-if="task" v-html="task.description"></div> -->
			<!-- <div class="pb-2 d-flex">
				<span class="fw-600 mr-2">Related To : </span>
				<span v-if="task && task.related_to"><v-chip
										class="ml-2 text-capitalize"
										color="red white--text"
										x-small
										label
										v-if="this.task && this.task.related_to"
										>{{ this.task.related_to.replace("-", " ") }}</v-chip
									></span>
			</div> -->
			<!-- <div class="pb-2 d-flex">
				<span class="fw-600 mr-2">Description : </span>
				<span v-if="task && task.description"><p v-html="task.description"></p></span>
			</div> -->
		</div>
		<DeleteTemplate
			type="Task"
			:deleteUrl="delURL"
			:deleteText="delTEXT"
			:deleteDialog="confirm_dialog"
			v-on:close="confirm_dialog = false"
			v-on:success="
				confirm_dialog = false;
				$emit('taskSuccess', true);
			"
		></DeleteTemplate>
	</div>
</template>
<script>
import { GET, PATCH } from "@/core/services/store/request.module";
import { SET_MESSAGE } from "@/core/services/store/common.module";
import DeleteTemplate from "@/view/components/DeleteTemplate";

export default {
	name: "TaskTab",
	components: {
		DeleteTemplate,
	},
	props: {
		open_details: {
			type: Boolean,
			default: false,
		},
		task: {
			type: Object,
		},
		indexTask: {
			type: Number,
		},
	},
	data() {
		return {
			delURL: null,
			unassignUserList: 0,
			delTEXT: null,
			confirm_dialog: false,
			assign_task: "",
			priorityList: [],
			checklists: [],
			userList: [],
		};
	},
	methods: {
		taskDelete(row) {
			this.delTEXT = row.title;
			this.delURL = `task/${row.id}`;
			this.confirm_dialog = true;
		},
		taskEdit(id, title) {
			this.$emit("singleTaskEdit", id);
			this.$emit("editTitle", title);
			this.$emit("taskSuccess", true);
		},
		routeToDetail(id) {
			this.$router.push({
				name: "task-detail",
				params: { id },
				query: { t: new Date().getTime() },
			});
		},
		getTaskChecklist() {
			this.$store
				.dispatch(GET, { url: `task/${this.task.id}/checklists` })
				.then((data) => {
					this.checklists = data;
				})
				.catch((error) => {
					console.log({ error });
				});
		},
		getTaskSetting() {
			this.$store
				.dispatch(GET, { url: `task-setting` })
				.then((data) => {
					this.priorityList = data.priority;
					this.userList = data.users;
					let unassignUser = this.userList.length - this.task.attendees.length;
					this.unassignUserList = unassignUser;
				})
				.catch((error) => {
					console.log({ error });
				});
		},
		updatePriority(id, value) {
			const taskupd = {
				priority: value,
			};
			this.$store
				.dispatch(PATCH, { url: `task-priority/${id}`, data: taskupd })
				.then(() => {
					this.$store.commit(SET_MESSAGE, [
						{ model: true, message: "Success ! Priority updated successfully." },
					]);
					this.$emit("priorityChange", true);
				})
				.catch((error) => {
					console.log({ error });
				});
		},
		userUpdate(id, value) {
			const taskupd = {
				assign: value,
			};
			this.$store
				.dispatch(PATCH, {
					url: `lead/${this.lead_id}/task-assign/${id}/attendee/${value}`,
					data: taskupd,
				})
				.then(() => {
					this.$store.commit(SET_MESSAGE, [
						{ model: true, message: "Success ! User updated successfully." },
					]);
					this.$emit("priorityChange", true);
					this.getTaskSetting();
				})
				.catch((error) => {
					console.log({ error });
				});
		},
		dueDate() {
			const dt = new Date(this.task.due_date);
			const dateFormate = dt.toLocaleString("en-us", { month: "short" }) + " " + dt.getDate();
			return dateFormate;
		},
		weekDate() {
			const numWeeks = 2;
			const dt = new Date(this.task.due_date);
			dt.setDate(dt.getDate() + numWeeks * 7);
			const dateFormate =
				"2 Week (" + dt.toLocaleString("en-US", { month: "short" }) + " " + dt.getDate() + ")";
			return dateFormate;
		},
		weekDateValue() {
			const numWeeks = 2;
			const dt = new Date(this.task.due_date);
			dt.setDate(dt.getDate() + numWeeks * 7);
			const dateFormate = dt.toLocaleDateString("fr-CA");
			return dateFormate;
		},
		monthDate() {
			const numWeeks = 4;
			const dt = new Date(this.task.due_date);
			dt.setDate(dt.getDate() + numWeeks * 7);
			const dateFormate =
				"1 Month (" + dt.toLocaleString("en-US", { month: "short" }) + " " + dt.getDate() + ")";
			return dateFormate;
		},
		monthDateValue() {
			const numWeeks = 4;
			const dt = new Date(this.task.due_date);
			dt.setDate(dt.getDate() + numWeeks * 7);
			const dateFormate = dt.toLocaleDateString("fr-CA");
			return dateFormate;
		},
		threeMonth() {
			const numWeeks = 12;
			const dt = new Date(this.task.due_date);
			dt.setDate(dt.getDate() + numWeeks * 7);
			const dateFormate =
				"3 month (" + dt.toLocaleString("en-US", { month: "short" }) + " " + dt.getDate() + ")";
			return dateFormate;
		},
		threeMonthValue() {
			const numWeeks = 12;
			const dt = new Date(this.task.due_date);
			dt.setDate(dt.getDate() + numWeeks * 7);
			const dateFormate = dt.toLocaleDateString("fr-CA");
			return dateFormate;
		},
		updateDueDate(id, value) {
			const due_dateupd = {
				due_date: value,
			};
			this.$store
				.dispatch(PATCH, { url: `task-due/${id}`, data: due_dateupd })
				.then(() => {
					this.$store.commit(SET_MESSAGE, [
						{ model: true, message: "Success ! Date updated successfully." },
					]);
					this.$emit("priorityChange", true);
				})
				.catch((error) => {
					console.log({ error });
				});
		},
		taskPinned(id) {
			this.$store
				.dispatch(PATCH, { url: `pin-task/${id}` })
				.then(() => {
					this.$store.commit(SET_MESSAGE, [
						{ model: true, message: "Success ! Task Pinned successfully." },
					]);
					this.$emit("taskSuccess", true);
				})
				.catch((error) => {
					console.log({ error });
				});
		},
	},
	beforeMount() {
		this.lead_id = this.$route.params.id;
	},
	mounted() {
		this.getTaskSetting();
		this.getTaskChecklist();
	},
};
</script>
